import Cookies from 'universal-cookie';
import { EventValues, GTMEventTypes } from '@sial/common-utils';
import { sendEvent } from '@utils/analytics/coreAnalytics';
export var sendGatedContentEvent = function sendGatedContentEvent(eventAction) {
  var cookies = new Cookies();
  var gaCookie = cookies.get('_ga') || EventValues.Empty;
  sendEvent({
    eventType: GTMEventTypes.AnalyticsEvent,
    payload: {
      eventCategory: 'gated content',
      eventAction: eventAction,
      eventLabel: gaCookie,
      eventInteractionType: 0
    }
  });
};