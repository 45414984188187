import { useEffect, useRef } from 'react';
export var useIntersectionObserver = function useIntersectionObserver(callback, options) {
  var targetRef = useRef(null);
  useEffect(function () {
    var observer = new IntersectionObserver(callback, options);

    if (targetRef.current) {
      observer.observe(targetRef.current);
    }

    return function () {
      if (targetRef.current) {
        observer.unobserve(targetRef.current);
      }
    };
  }, [targetRef, callback, options]);
  return targetRef;
};