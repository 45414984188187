import { useState, useEffect } from 'react';
import { QueryParamKeys } from './types';
import { DEFAULT_SEARCH_VALUES } from './useCategoryProductSearchQueryParams';
export var DEFAULT_SEARCH_RESULTS = {
  items: [],
  facets: [],
  metadata: {
    itemCount: 0,
    page: DEFAULT_SEARCH_VALUES[QueryParamKeys.Page],
    perPage: DEFAULT_SEARCH_VALUES[QueryParamKeys.PerPage],
    numPages: 0
  }
};

var useCacheCategoryProductSearch = function useCacheCategoryProductSearch(searchResults, loading) {
  var _useState = useState(DEFAULT_SEARCH_RESULTS),
      cache = _useState[0],
      setCache = _useState[1];

  useEffect(function () {
    if (!loading && searchResults !== DEFAULT_SEARCH_RESULTS) {
      setCache(searchResults);
    }
  }, [loading, searchResults]);
  return cache;
};

export default useCacheCategoryProductSearch;