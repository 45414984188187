import { useRouter } from 'next/router';
import { parseRegionalUrl } from '@utils/regional';
import { useUserSession } from '@utils/useUserSession';
import { useCountriesData } from '@utils/useCountriesData';
import { useState } from 'react';
import { useSessionStorage } from 'react-storage';
import { ProductCardType } from '@utils/searchUtils'; // UIPT-216
// This will be used for category & application AEM pages.

var useAemStaticContentOptlyCarousel = function useAemStaticContentOptlyCarousel(sessionStorageName) {
  var _parseRegionalUrl$aft;

  var router = useRouter();
  var path = (_parseRegionalUrl$aft = parseRegionalUrl(router.asPath).afterBasename) === null || _parseRegionalUrl$aft === void 0 ? void 0 : _parseRegionalUrl$aft.split('?')[0];

  var _useUserSession = useUserSession(),
      userSession = _useUserSession.userSession;

  var _useCountriesData = useCountriesData(),
      getCountryProp = _useCountriesData.getCountryProp; // Check for session data for AEM page tests


  var _useState = useState(useSessionStorage(sessionStorageName)),
      sessionStorageData = _useState[0];

  if (!sessionStorageData) {
    var _model = '0';
    return {
      model: _model
    };
  }

  var carouselEndpoint = sessionStorageData === null || sessionStorageData === void 0 ? void 0 : sessionStorageData.type;
  var carouselTitle = carouselEndpoint === 'catPageBestSellersAPI' ? ProductCardType.BestSellers : ProductCardType.NewArrivals;

  function getPageCode(path) {
    // eslint-disable-next-line sonarjs/max-switch-cases
    switch (path) {
      case '/products/industrial-microbiology/microbial-identification-tests':
        return 'S863';

      case '/products/analytical-chemistry/analytical-chromatography/hplc-columns':
        return 'S151';

      case '/products/labware/cell-culture-and-cryogenics/millicell-cell-culture-inserts-and-plates':
        return 'S372';

      case '/products/filtration/laboratory-syringe-filters/millex-syringe-filters':
        return 'S366';

      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/cell-freezing-reagents':
        return 'S315';

      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/fbs':
        return 'S324';

      case '/products/materials-science/biomedical-materials/3d-bioprinting':
        return 'S307';

      case '/products/industrial-microbiology/microbial-culture-media':
        return 'S194';

      case '/products/molecular-biology-and-functional-genomics/roche-life-science-products':
        return 'S348';

      case '/products/protein-biology/protein-sample-prep/flag-purification':
        return 'S402';

      case '/products/chemistry-and-biochemicals/biochemicals/metabolism-assay-kits':
        return 'S241';

      case '/products/labware/labware-partners/cytiva':
        return 'S395';

      case '/products/materials-science/energy-materials/high-purity-salts':
        return 'S280';

      case '/products/pharma-and-biopharma-manufacturing/formulation/liquid-formulation':
        return 'S138';

      case '/products/pharma-and-biopharma-manufacturing/formulation/biomolecule-formulation':
        return 'S140';

      case '/products/pharma-and-biopharma-manufacturing/formulation/injectables-parenteral-formulations':
        return 'CP007';

      case '/products/cell-culture-and-analysis/cell-culture-supplements-and-reagents/albumins-and-transport-proteins':
        return 'S319';

      case '/products/industrial-microbiology/microbial-filtration':
        return 'S198';

      case '/products/industrial-microbiology/sterility-testing-consumables-media-and-filtration':
        return 'S199';

      default:
        return '0';
    }
  }

  var regionCode = getCountryProp(userSession.country, 'regionCode');
  var regions = {
    AP: 'APAC',
    EU: 'EMEA',
    AF: 'EMEA',
    SA: 'LATAM'
  }; // The catPageBestSellersAPI requires we add a regin code to the page code. IE: S151_NA

  var data = getPageCode(path || '0');

  if (data === '0' || carouselEndpoint === 'catPageNewProductsAPI') {
    var _model2 = data;
    return {
      model: _model2,
      carouselTitle: carouselTitle,
      carouselEndpoint: carouselEndpoint
    };
  }

  var region = regions[regionCode] || regionCode;
  var model = "".concat(data, "_").concat(region);
  return {
    model: model,
    carouselTitle: carouselTitle,
    carouselEndpoint: carouselEndpoint
  };
};

export default useAemStaticContentOptlyCarousel;