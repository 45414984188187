import _defineProperty from "/builds/PDteTNAh/3/ms/ecom/deep/presentation-react/apps/next-commerce/node_modules/next/dist/compiled/@babel/runtime/helpers/esm/defineProperty.js";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

/*
    Dedicated file to include all React components that map to an AEM component
*/
import AemComponents from '@sial/aem-cms-components';
import CategorySearchResults from './CategorySearchResults';
import Contact from './Contact';
import Container from './Container';
import DynamicPlaceholder from './DynamicPlaceholder';
import DynamicProductTable from './DynamicProductTable';
import EventListing from './EventListing';
import EventSidebar from './EventSidebar';
import FFCertsBrandImage from './FFCertsBrandImage';
import FormAssembly from './FormAssembly';
import LocationMap from './LocationMap';
import Page from './Page';
import PressReleaseGrid from './PressReleaseGrid';
import PressReleaseListing from './PressReleaseListing';
import RelatedProductResources from './RelatedProductResources';
import RelatedWebinar from './RelatedWebinar';
import TrialLicense from './TrialLicense';
import UpcomingEventsGrid from './UpcomingEventsGrid';
import WebinarListing from './WebinarListing';
import WebinarSidebar from './WebinarSidebar';
import RelatedProducts from './RelatedProducts';
import ProductRecommendationCarousel from './ProductRecommendationCarousel';
import TextBanner from './TextBanner';
import HorizontalTab from './HorizontalTab';
import SiteMap from './SiteMap';
import UserEngageCarousel from './UserEngageCarousel';
import ProductSelector from './ProductSelector';

var Components = _objectSpread(_objectSpread({}, AemComponents), {}, {
  CategorySearchResults: CategorySearchResults,
  Contact: Contact,
  DynamicProductTable: DynamicProductTable,
  DynamicPlaceholder: DynamicPlaceholder,
  FFCertsBrandImage: FFCertsBrandImage,
  TextBanner: TextBanner,
  FormAssembly: FormAssembly,
  TrialLicense: TrialLicense,
  Container: Container,
  EventListing: EventListing,
  EventSidebar: EventSidebar,
  LocationMap: LocationMap,
  PressReleaseGrid: PressReleaseGrid,
  PressReleaseListing: PressReleaseListing,
  RelatedProductResources: RelatedProductResources,
  RelatedWebinar: RelatedWebinar,
  UpcomingEventsGrid: UpcomingEventsGrid,
  WebinarListing: WebinarListing,
  WebinarSidebar: WebinarSidebar,
  RelatedProducts: RelatedProducts,
  ProductRecommendationCarousel: ProductRecommendationCarousel,
  Page: Page,
  HorizontalTab: HorizontalTab,
  SiteMap: SiteMap,
  UserEngageCarousel: UserEngageCarousel,
  ProductSelector: ProductSelector
});

export { Components, Page };