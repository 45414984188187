import { defineMessages } from 'react-intl';
export var messages = defineMessages({
  EVENT_BOOTH_LABEL: {
    id: 'EVENT_BOOTH_LABEL',
    defaultMessage: 'Booth'
  },
  EVENT_ONLINE_LABEL: {
    id: 'EVENT_ONLINE_LABEL',
    defaultMessage: 'Virtual'
  },
  EVENT_VENUE_LABEL: {
    id: 'EVENT_VENUE_LABEL',
    defaultMessage: 'Venue'
  },
  GATED_CONTENT_INSTRUCTIONS: {
    id: 'GATED_CONTENT_INSTRUCTIONS',
    defaultMessage: 'To continue reading please sign in or create an account.'
  },
  LATEST_PRESS_RELEASES: {
    id: 'LATEST_PRESS_RELEASES',
    defaultMessage: 'Latest Press Releases'
  },
  MORE_EVENTS_LINK_TITLE: {
    id: 'MORE_EVENTS_LINK_TITLE',
    defaultMessage: 'More Events'
  },
  MORE_PRESS_LINK_TITLE: {
    id: 'MORE_PRESS_LINK_TITLE',
    defaultMessage: 'More Press Releases'
  },
  MORE_WEBINARS_LINK_TITLE: {
    id: 'MORE_WEBINARS_LINK_TITLE',
    defaultMessage: 'More Webinars'
  },
  RELATED_WEBINARS: {
    id: 'RELATED_WEBINARS',
    defaultMessage: 'Related Webinars'
  },
  SIGN_IN_TO_CONTINUE: {
    id: 'SIGN_IN_TO_CONTINUE',
    defaultMessage: 'Sign In To Continue'
  },
  UPCOMING_EVENTS: {
    id: 'UPCOMING_EVENTS',
    defaultMessage: 'Upcoming Events'
  },
  WEBINAR_DURATION_LABEL: {
    id: 'WEBINAR_DURATION_LABEL',
    defaultMessage: 'Duration'
  },
  WEBINARS_FILTER_LABEL: {
    id: 'WEBINARS_FILTER_LABEL',
    defaultMessage: 'Filter webinars'
  },
  WEBINAR_ON_DEMAND_TAB: {
    id: 'WEBINAR_ON_DEMAND_TAB',
    defaultMessage: 'On Demand'
  },
  WEBINAR_PRESENTED_LABEL: {
    id: 'WEBINAR_PRESENTED_LABEL',
    defaultMessage: 'Presented'
  },
  WEBINAR_SESSION_LABEL: {
    id: 'WEBINAR_SESSION_LABEL',
    defaultMessage: 'Session'
  },
  WEBINAR_UPCOMING_TAB: {
    id: 'WEBINAR_UPCOMING_TAB',
    defaultMessage: 'Upcoming'
  }
});
export default messages;